import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
    userId: { type: "shabdshodhapp/setUserId", payload: null },
    name: { type: "shabdshodhapp/setName", payload: null },
    notification: { type: "shabdshodhapp/setNotification", payload: { datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'), calltime: dayjs().format('YYYY-MM-DD HH:mm:ss'), visible: true } },
    hiddenColumn: { type: "shabdshodhapp/setHiddenColumn", payload: {} }
};

export const globalSlice = createSlice({
    name: "shabdshodhapp",
    initialState,
    reducers: {
        setUserId: (state, userId) => {
            state.userId = userId;
        },
        setName: (state, name) => {
            state.name = name;
        },
        setNotification: (state, notification) => {
            state.notification = notification;
        },
        setHiddenColumn: (state, column) => {
            state.hiddenColumn = column;
        }
    },
});

export const { setLogin } = globalSlice.actions;
export const { setUserId } = globalSlice.actions;
export const { setName } = globalSlice.actions;
export const { setNotification } = globalSlice.actions;
export const { setHiddenColumn } = globalSlice.actions;

export default globalSlice.reducer;
// mui theme settings
export const themeSettings = () => {
    return {
        palette: {
            // palette values for dark mode
            primary: {
                main: "#ff8f00",
                contrastText: "#fff"
            },
            secondary: {
                main: "#2C3333",
            },
            background: {
                default: "#ffffff",
            },
        },
        typography: {
            h1: {
                fontSize: 32,
                fontWeight: 800,
                fontFamily: "'Hind', sans-serif"
            },
            h2: {
                fontSize: 28,
                fontWeight: 800,
                fontFamily: "'Mukta', sans-serif"
            },
            h3: {
                fontSize: 24,
                fontWeight: 700,
                fontFamily: "'Hind', sans-serif"
            },
            h4: {
                fontSize: 22,
                fontWeight: 700,
                fontFamily: "'Hind', sans-serif"
            },
            h5: {
                fontSize: 18,
                fontWeight: 600,
                lineHeight: 1.46,
                fontFamily: "'Hind', sans-serif"
            },
            h6: {
                fontSize: 14,
                fontWeight: 600,
                fontFamily: "'Mukta', sans-serif"
            },
            p: {
                fontSize: 13,
                fontWeight: 600,
                fontFamily: "'Hind', sans-serif"
            },
            button: {
                fontFamily: "'Hind', sans-serif",
                fontWeight: 600,
                fontSize: 18,
            },
        }
    };
};


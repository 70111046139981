import React, { useState, useRef, useEffect } from "react";
import { Box, CircularProgress, Button } from "@mui/material";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import PdfControl from "./pdfcontrol";
import { useNavigate } from "react-router-dom";

//PDFjs worker from an external cdn

const Pdf = ({ url, ratio, scale = 1, control }) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const navigate = useNavigate();
    const [zscale, setZScale] = useState(scale);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState(true);

    function onDocumentLoadSuccess({ numPages }) {
        setTotalPage(numPages);
        setLoading(false);
    }

    const useContainerDimensions = myRef => {
        const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

        useEffect(() => {
            const getDimensions = () => ({
                width: myRef.current.offsetWidth,
                height: myRef.current.offsetHeight
            })

            const handleResize = () => {
                setDimensions(getDimensions())
            }

            if (myRef.current) {
                setDimensions(getDimensions())
            }

            window.addEventListener("resize", handleResize)

            return () => {
                window.removeEventListener("resize", handleResize)
            }
        }, [myRef])

        return dimensions;
    };

    const componentRef = useRef();
    const { width, height } = useContainerDimensions(componentRef);

    return (
        <>
            {control && <>
                <PdfControl
                    zscale={zscale}
                    setZScale={setZScale}
                    totalPage={totalPage}
                    page={page}
                    setPage={setPage}
                    file={url}
                />
            </>
            }
            <Box ref={componentRef} sx={{ width: "100%", overflowY: "hidden", textAlign: "center" }}>
                {loading && <CircularProgress />}
                <div className="doc-pdf">
                    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page
                            scale={zscale}
                            devicePixelRatio={ratio}
                            pageNumber={page}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            width={zscale * width - 5}
                            height={zscale * height}
                        />
                        {control && <Box sx={{ marginBottom: "90px", marginTop: { xs: "0px", md: "-50px" }, textAlign: "center" }}>
                            <Button variant='outlined' color='error' onClick={() => navigate('/')}>Close</Button>
                        </Box>}
                    </Document>
                </div>
            </Box>
        </>
    )
}

export default Pdf
import React, { useMemo, useState, useEffect, lazy, Suspense } from "react";
import { CssBaseline, IconButton, ThemeProvider, Modal, Paper, Typography, Button } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { Close } from "@mui/icons-material";
import { browserName, isAndroid, isMobile } from 'react-device-detect';
import axios from 'axios';
import { themeSettings } from "./theme";
import CircleLoading from "./components/global/circleloading";
import { MyContextProvider } from "./components/global/MyContext";
import playStore from "./assets/images/playstore.png";
import sslogo from "./assets/images/sslogo.png";

import Main from './view/main';
import Outside from "./view/outside";
import Home from './view/home';
import CatPost from './view/catpost';
import Search from './view/search';
import Video from './view/video';
import VideoId from './view/videoid';
import Epaper from './view/epaper';
import EpaperId from './view/epaperid';
import Short from "./view/short";

const Post = lazy(() => import('./view/post'));
const Notification = lazy(() => import('./view/notification'));

const PrivacyPolicy = lazy(() => import("./view/about/privacy-policy"));
const ContactUs = lazy(() => import("./view/about/contact-us"));
const About = lazy(() => import("./view/about/about"));
const Faq = lazy(() => import("./view/about/faq"));


function App() {

  const [user, setUser] = useState({});
  const [notification, setNotification] = useState([]);
  const [post, setPost] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useMemo(() => createTheme(themeSettings()));
  const apiUrl = process.env.REACT_APP_API_URL;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getCategory = () => {
    axios.get(apiUrl + 'category/get')
      .then(function (response) {
        if (response.data.status) {
          setCategory(response.data.data);
          setLoading(false);
        }
        else {
          setCategory([]);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    // Set up a timeout to call handleOpen after 8 seconds
    const timeoutId = setTimeout(() => {
      handleOpen();
    }, 10000); // 8 seconds in milliseconds

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          style={{ marginTop: "50px", fontSize: { xs: "12px", md: "14px" } }}
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          preventDuplicate={true}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <Close fontSize="small" sx={{ color: "#fff" }} />
            </IconButton>
          )}
        >
          <MyContextProvider value={{ apiUrl, user, setUser }}>

            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Main category={category} notification={notification} setNotification={setNotification} /></Suspense>} >
                  <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Home post={post} setPost={setPost} /></Suspense>} />
                  <Route exact path="/:url" element={<Suspense fallback={<CircleLoading />}><CatPost /></Suspense>} />
                  <Route exact path="/:url/:id" element={<Suspense fallback={<CircleLoading />}><Post /></Suspense>} />
                  <Route exact path="notification" element={<Suspense fallback={<CircleLoading />}><Notification /></Suspense>} />
                </Route>
                <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Outside notification={notification} setNotification={setNotification} /></Suspense>} >
                  <Route exact path="search" element={<Suspense fallback={<CircleLoading />}><Search /></Suspense>} />
                  <Route exact path="video" element={<Suspense fallback={<CircleLoading />}><Video /></Suspense>} />
                  <Route exact path="video/yt/shorts/" element={<Suspense fallback={<CircleLoading />}><Short /></Suspense>} />
                  <Route exact path="video/yt/shorts/:id" element={<Suspense fallback={<CircleLoading />}><VideoId /></Suspense>} />
                  <Route exact path="epaper" element={<Suspense fallback={<CircleLoading />}><Epaper /></Suspense>} />
                  <Route exact path="epaper/:id" element={<Suspense fallback={<CircleLoading />}><EpaperId /></Suspense>} />

                  <Route exact path="faq" element={<Suspense fallback={<CircleLoading />}><Faq /></Suspense>} />
                  <Route exact path="about-us" element={<Suspense fallback={<CircleLoading />}><About /></Suspense>} />
                  <Route exact path="contact-us" element={<Suspense fallback={<CircleLoading />}><ContactUs /></Suspense>} />
                  <Route exact path="privacy-policy" element={<Suspense fallback={<CircleLoading />}><PrivacyPolicy /></Suspense>} />
                  <Route exact path="404" element={<h1>404</h1>} />
                </Route>
              </Routes>
              {isAndroid && isMobile && !browserName.toLowerCase().includes("web") &&
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 600,
                    minWidth: 350,
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    p: 2,
                    alignItems: "center",
                    textAlign: "center"
                  }}>
                    <IconButton sx={{ position: "absolute", top: "5px", right: "5px" }} onClick={handleClose}><Close /></IconButton>
                    <img src={sslogo} width="25%" />
                    <Typography variant="h5">Download our App from PlaySotre</Typography>
                    <Typography variant="p" sx={{ fontWeight: "400" }}>हमारा उद्देश्य है आपको विश्व भर से ताज़ा, विश्वसनीय और महत्वपूर्ण समाचार प्रदान करना है।</Typography>
                    <Link to="https://play.google.com/store/apps/details?id=com.dainikshabdshodh"><img src={playStore} width="50%" /></Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.dainikshabdshodh">Downlaod Now !</Link>
                  </Paper>
                </Modal>
              }
            </BrowserRouter>
          </MyContextProvider>
        </SnackbarProvider>
      </ThemeProvider>

    </div>
  );
}

export default App;

import { useTheme } from "@emotion/react";
import { ZoomIn, ZoomOut, ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button, IconButton, Paper } from "@mui/material";
import React from "react";

const PdfControl = (props) => {
    const { file, zscale, setZScale, page, setPage, totalPage } = props;

    const theme = useTheme();

    const isMinZoom = zscale <= 1.0;
    const isMaxZoom = zscale >= 2.0;

    const zoomOut = () => {
        if (!isMinZoom) setZScale(zscale - 0.1);
    };

    const zoomIn = () => {
        if (!isMaxZoom) setZScale(zscale + 0.1);
    };

    const prevPage = () => { setPage(page - 1 <= 1 ? 1 : page - 1) }
    const nextPage = () => { setPage(page + 1 >= totalPage ? totalPage : page + 1) }

    return (
        <>
            <IconButton onClick={prevPage} color='primary' disabled={page == 1 ? true : false} sx={{ border: "2px solid " + theme.palette.primary.main, padding: "5px", position: "fixed", top: "50%", left: "20px", zIndex: "999" }}><ArrowBack fontSize='large' /></IconButton>
            <IconButton onClick={nextPage} color='primary' disabled={page == totalPage ? true : false} sx={{ border: "2px solid " + theme.palette.primary.main, padding: "5px", position: "fixed", top: "50%", right: "20px", zIndex: "999" }}><ArrowForward fontSize='large' /></IconButton>

            {/* <Paper sx={{ position: "fixed", top: "100vh", left: "50vw", transform: "translate(-50%, -120%)", zIndex: 99, padding: "0px 10px", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <IconButton size="large" disabled={isMinZoom} onClick={zoomOut} color="primary"><ZoomOut fontSize="large" /></IconButton>
                <span>{(zscale * 100).toFixed()}%</span>
                <IconButton size="large" disabled={isMaxZoom} onClick={zoomIn} color="primary"><ZoomIn fontSize="large" /></IconButton>
            </Paper> */}
        </>
    );
};

export default PdfControl;

import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import dayjs from 'dayjs';
import Pdf from '../components/pdf';
import { Box, Button } from '@mui/material';
import { useMycontext } from '../components/global/MyContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from '../components/global/navbar';
import { isIPad13 } from 'react-device-detect';

const EpaperId = (props) => {

    const theme = useTheme();
    const { apiUrl } = useMycontext();
    const { id } = useParams();

    return (
        <>
            <Helmet>
                <title>{"ई-पेपर-" + id}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <Box sx={{ padding: { xs: "0px 5px 0px 5px", md: "0px 10px 0px 10px" } }}>
                <Box>

                    <Pdf url={apiUrl + "assets/e-paper/e-paper-shabd-shodh_" + id + ".pdf"} ratio={isIPad13 ? 2 : 5} control={true} />
                </Box>
            </Box>
        </>
    )
}

export default EpaperId
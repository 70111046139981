import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from "react-player";
import { Box, Container } from "@mui/material";

import { useInView } from "framer-motion";

import axios from 'axios';
import { useMycontext } from '../components/global/MyContext';
import { useNavigate, useParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Mousewheel, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import Navbar from "../components/global/navbar";
import Navdown from "../components/global/navdown";
import CircleLoading from '../components/global/circleloading';
import { Helmet } from 'react-helmet';

const Slide = ({ link, vid }) => {

    const ref = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const isInView = useInView(ref, { once: false });
    const [playing, setPlaying] = useState(true);

    return (
        <>
            <Helmet>
                <title>वीडियो</title>
            </Helmet>
            <Box ref={ref} sx={{ height: "80%", width: "100%", position: "absolute", top: "50%", transform: "translateY(-50%)", zIndex: 1 }} onClick={() => setPlaying(!playing)}></Box>
            <ReactPlayer
                width="100%"
                height="100%"
                config={{
                    youtube: {
                        playerVars: {
                            modestbranding: 1,
                            autoplay: 1,
                            controls: 1,
                            loop: 1,
                        }
                    }
                }}
                loop={true}
                controls={false}
                playing={isInView && playing}
                url={isInView ? link : ""}
            />
        </>
    );
};

const Short = (props) => {

    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const { apiUrl } = useMycontext();
    const { id } = useParams();
    const navigate = useNavigate();
    const getVideo = () => {
        axios.get(apiUrl + 'video/get?offset=0&id=')
            .then(function (response) {
                if (response.data.status) {
                    setVideos(response.data.data);
                }
                else {
                    setVideos([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                setLoading(false);
            })
            ;
    }

    useEffect(() => {
        getVideo();
    }, []);

    return (
        <Box>
            {loading && <CircleLoading />}
            <Container maxWidth="fluid" sx={{ padding: { xs: "0px 0px 0px 0px", md: "0px 0px" }, height: { xs: "calc(100vh - 135px)", md: "calc(100vh - 70px)" } }}>
                <Swiper
                    onSlideChange={(e) => {
                        setActiveIndex(e.activeIndex);
                    }}
                    direction={"vertical"}
                    slidesPerView={true}
                    spaceBetween={30}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Mousewheel, Pagination, Keyboard]}
                    className="mySwiper"
                >
                    {videos.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <Slide link={item.link} vid={item.video_id} activeIndex={activeIndex} />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Container>
        </Box>
    );
}

export default Short

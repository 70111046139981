import React from 'react'
import { Paper, Box, Typography, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { HomeSharp, Newspaper, SlowMotionVideo, Search } from '@mui/icons-material';
import { browserName, isMobile, isIOS } from 'react-device-detect';
const Navdown = ({ flag }) => {
    return (
        <>
            <Box sx={{ position: "fixed", zIndex: 99, bottom: "0px", paddingBottom: "5px", width: flag ? "100%" : "calc(100% - 10px)", display: { xs: "block", md: "none" }, background: "#fff" }}>
                <Paper sx={{ display: "flex", justifyContent: "space-around", borderRadius: "15px" }}>
                    <NavLink to={"/"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <HomeSharp fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>होम</Typography>
                            </Button>
                        )}
                    </NavLink>
                    {isIOS && isMobile && browserName.toLowerCase().includes("web") ? "" :
                        <NavLink to={"/video/yt/shorts/"} >
                            {({ isActive }) => (
                                <Button color={isActive ? "primary" : "secondary"}
                                    fullWidth
                                    sx={{
                                        height: "60px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "0px",
                                        border: "none",
                                        padding: "0px"
                                    }}
                                >
                                    <SlowMotionVideo fontSize={isActive ? "large" : "medium"} />
                                    <Typography variant='p' sx={{ fontSize: "15px" }}>वीडियो</Typography>
                                </Button>
                            )}
                        </NavLink>
                    }
                    <NavLink to={"/search"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <Search fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>खोज</Typography>
                            </Button>
                        )}
                    </NavLink>

                    <NavLink to={"/epaper"} >
                        {({ isActive }) => (
                            <Button color={isActive ? "primary" : "secondary"}
                                fullWidth
                                sx={{
                                    height: "60px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px",
                                    border: "none",
                                    padding: "0px"
                                }}
                            >
                                <Newspaper fontSize={isActive ? "large" : "medium"} />
                                <Typography variant='p' sx={{ fontSize: "15px" }}>ई-पेपर</Typography>
                            </Button>
                        )}
                    </NavLink>
                </Paper>
            </Box>
        </>
    )
}

export default Navdown
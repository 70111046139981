import { Box, Button, Paper, Container, Avatar, Chip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import axios from 'axios'
import Navbar from '../components/global/navbar'
import Navdown from '../components/global/navdown'
import { useMycontext } from '../components/global/MyContext'
import CircleLoading from '../components/global/circleloading'
import { Helmet } from 'react-helmet'


const Outside = (props) => {

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            </Helmet>
            <Box sx={{ display: "flex", width: "100%", height: "100#", justifyContent: 'center', alignItems: "center" }}>
                <Box sx={{ flex: 1, padding: "0px 0px 0px 0px" }}>
                    <Navbar notification={props.notification} setNotification={props.setNotification} />
                    <Navdown flag={true} />
                    <Container maxWidth="fluid" sx={{ padding: { xs: "0px 0px 70px 0px", md: "0px" } }}>
                        <Outlet context={{ notification: props.notification, setNotification: props.setNotification }} />
                    </Container>
                </Box >
            </Box >

        </>
    )
}

export default Outside
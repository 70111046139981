import React, { useState, useEffect } from 'react'
import { Avatar, Box, Container, IconButton, Paper, Typography } from '@mui/material'
import axios from 'axios'
import Navbar from '../components/global/navbar'
import Navdown from '../components/global/navdown'
import { useMycontext } from '../components/global/MyContext'
import dayjs from 'dayjs'
import { PlayCircleOutline } from '@mui/icons-material'
import CircleLoading from '../components/global/circleloading'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Video = () => {

    const { apiUrl } = useMycontext();
    const navigate = useNavigate();
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const getVideoData = () => {
        axios.get(apiUrl + 'video/getdata?offset=0')
            .then(function (response) {
                if (response.data.status) {
                    setVideos(response.data.data);
                    setLoading(false);
                }
                else {
                    setVideos([]);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getVideoData();
    }, []);


    return (
        <>
            <Helmet>
                <title>वीडियो</title>
            </Helmet>
            {loading && <CircleLoading />}
            <Box sx={{ padding: { xs: "0px 5px 0px 5px", md: "0px 10px 0px 10px" } }}>
                <Container sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: '10px', padding: { xs: "7px 5px 70px 5px", md: "10px 5px" }, width: "100%" }}>
                    {videos.map((item, index) => {
                        return (
                            <Paper key={index} sx={{ borderRadius: "15px", width: { xs: "48%", md: "200px" }, height: "auto", position: 'relative', cursor: 'pointer' }} onClick={() => { navigate('yt/shorts/') }}>
                                <Avatar src={item.metadata.thumbnailUrl} style={{ borderRadius: "15px", width: "100%", height: "100%", aspectRatio: 2 / 3 }} />
                                <Box sx={{ padding: "10px", textAlign: "center", width: "100%", color: "#fff", position: "absolute", bottom: "0px", zIndex: 1, borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px", background: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5536808473389356) 70%, rgba(0,0,0,0) 100%)" }}>
                                    <Typography variant='h6' sx={{ lineHeight: "15px" }}>{item.title}</Typography>
                                    <Typography variant='p'>{dayjs(item.metadata.publishedAt).format('DD-MMM-YYYY hh:mm:ss A')}</Typography>
                                </Box>
                                <IconButton size='large' sx={{ position: 'absolute', top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "#fff" }}><PlayCircleOutline fontSize='large' /></IconButton>
                            </Paper>
                        )
                    })}
                </Container>
            </Box>
        </>
    )
}

export default Video
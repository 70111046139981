import React, { useEffect, useState } from 'react'
import { Box, Container, FormControl, Input, InputAdornment, Avatar, Chip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import Navbar from '../components/global/navbar';
import Navdown from '../components/global/navdown';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useMycontext } from '../components/global/MyContext';
import CircleLoading from '../components/global/circleloading';
import { Helmet } from 'react-helmet';

const Search = () => {

    const { apiUrl } = useMycontext();
    const [category, setCategory] = useState([]);
    const [newCategory, setNewCategory] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCategory = () => {
        axios.get(apiUrl + 'category/get')
            .then(function (response) {
                if (response.data.status) {
                    setCategory(response.data.data);
                    setNewCategory(response.data.data);
                    setLoading(false);
                }
                else {
                    setCategory([]);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(false);
            });
    }

    const CallFilter = (searchtext) => {
        setNewCategory(category.filter((item) => {
            return item.eng_sub_category.toLowerCase().match(searchtext.toLowerCase());
        }));
    }

    useEffect(() => {
        getCategory();
    }, [])

    return (
        <>
            <Helmet>
                <title>खोज</title>
            </Helmet>
            {loading && <CircleLoading />}
            <Box sx={{ padding: { xs: "0px 5px 0px 5px", md: "0px 10px 0px 10px" } }}>
                <Container sx={{ display: "flex", gap: "30px", flexDirection: "column", alignItems: "center", padding: { xs: "7px 5px 70px 5px", md: "10px 5px" }, width: "100%", overflowX: "hidden" }}>
                    <FormControl sx={{ width: { xs: "80%", md: "50%" } }} variant="outlined">
                        <Input
                            sx={{ height: "50px", fontSize: "30px" }}
                            placeholder="Search..."
                            onChange={(e) => CallFilter(e.target.value)}
                            id="outlined-adornment-weight"
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon fontSize='large' />
                                </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                "aria-label": "weight"
                            }}
                        />
                    </FormControl>
                    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "10px", maxWidth: { xs: "80%", md: "50%" } }}>
                        {newCategory.map((item, index) => {
                            return (
                                <NavLink key={index} to={"/" + item.eng_sub_category}>
                                    {({ isActive }) => (
                                        <Chip sx={{ fontWeight: "600", fontSize: "18px", fontFamily: "Mukta, sans-serif" }}
                                            avatar={<Avatar alt="Natacha" src={apiUrl + "assets/cat-img/" + item.id + ".png"} />}
                                            variant={isActive ? "filled" : "outlined"} label={item.sub_category} color={isActive ? 'primary' : 'default'} />
                                    )}
                                </NavLink>
                            )
                        })}
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Search
import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const CircleLoading = () => {
    return (
        <Box sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "999"
        }}>
            <CircularProgress color="primary" />
        </Box>
    )
}

export default CircleLoading
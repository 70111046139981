import { Box, Button, Paper, Container, Avatar, Chip, Typography, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, Link } from 'react-router-dom'
import axios from 'axios'
import Navbar from '../components/global/navbar'
import Navdown from '../components/global/navdown'
import topnews from '../assets/icons/top-news.png'
import { useMycontext } from '../components/global/MyContext'
import CircleLoading from '../components/global/circleloading'
import ad1 from '../assets/images/ad1.jpeg'
import growth from '../assets/icons/growth.png'
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet'
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material'

const Main = (props) => {

    const { apiUrl } = useMycontext();

    const handleScroll = () => {
        const leftSidebar = document.querySelector(".left-sidebar");
        const leftSidebarContent = document.querySelector(".left-content-wrapper");

        const rightSidebar = document.querySelector(".right-sidebar");
        const rightSidebarContent = document.querySelector(".right-content-wrapper");

        const scrollTop = window.scrollY;
        const viewportHeight = window.innerHeight;
        const leftSidebarTop = leftSidebar?.getBoundingClientRect().top + window.pageYOffset;
        const leftContentHeight = leftSidebarContent?.getBoundingClientRect().height;

        const rightSidebarTop = rightSidebar?.getBoundingClientRect().top + window.pageYOffset;
        const rightContentHeight = rightSidebarContent?.getBoundingClientRect().height;

        if (scrollTop >= leftContentHeight - viewportHeight + leftSidebarTop) {
            leftSidebarContent.style.transform = `translateY(-${leftContentHeight - viewportHeight + leftSidebarTop + 10}px)`;
            leftSidebarContent.style.position = "fixed";
        } else {
            leftSidebarContent.style.transform = "";
            leftSidebarContent.style.position = "";
        }

        if (scrollTop >= rightContentHeight - viewportHeight + rightSidebarTop) {
            rightSidebarContent.style.transform = `translateY(-${rightContentHeight - viewportHeight + rightSidebarTop + 10}px)`;
            rightSidebarContent.style.position = "fixed";
        } else {
            rightSidebarContent.style.transform = "";
            rightSidebarContent.style.position = "";
        }
    };

    useEffect(() => {

        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            </Helmet>
            <Box sx={{ display: "flex", width: "100%", height: "100#", justifyContent: 'center', alignItems: "center" }}>
                <Box sx={{ flex: 1, padding: { xs: "0px 5px 0px 5px", md: "0px 5px 0px 5px" } }}>
                    <Navbar notification={props.notification} setNotification={props.setNotification} />
                    <Navdown />
                    <Container maxWidth="lg" sx={{ padding: { xs: "0px 5px 70px 5px", md: "5px 5px" } }}>
                        <div className="sidebar-container">
                            <div className="left-sidebar">
                                <div className="left-content-wrapper">
                                    <Paper sx={{ borderRadius: "15px" }}>
                                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                                            <NavLink to={"/"}>
                                                {({ isActive }) => (
                                                    <Button fullWidth size='' variant={isActive ? 'outlined' : 'text'} color={isActive ? "primary" : "secondary"} startIcon={<Avatar src={topnews} sx={{ marginLeft: "5px", borderRadius: "0px", maxWidth: "40px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "0px", fontSize: "20px" }}>मुख्य समाचार</Button>
                                                )}
                                            </NavLink>
                                            {props.category.map((item, index) => {
                                                return (
                                                    <NavLink key={index} to={item.eng_sub_category}>
                                                        {({ isActive }) => (
                                                            <Button fullWidth size='' variant={isActive ? 'outlined' : 'text'} color={isActive ? "primary" : "secondary"} startIcon={<Avatar src={apiUrl + "assets/cat-img/" + item.id + ".png"} sx={{ marginLeft: "5px", borderRadius: "0px", maxWidth: "40px" }} />} sx={{ borderRadius: "10px", justifyContent: "flex-start", gap: "0px", fontSize: "20px" }}>{item.sub_category}</Button>
                                                        )}
                                                    </NavLink>
                                                )
                                            })}
                                        </Box>
                                        <Box sx={{ textAlign: "center" }}>
                                            <Typography sx={{ fontSize: "13px" }}>---------- Follow us on ----------</Typography>
                                            <Box sx={{ paddingBottom: "10px", display: "flex", gap: "10px", justifyContent: "center" }}>
                                                <Link target='_blank' to="https://www.facebook.com/dainik.shabdshodh/"><IconButton><Facebook sx={{ color: "#1877F2" }} /></IconButton></Link>
                                                <Link target='_blank' to="https://twitter.com/shabdshodh"><IconButton><Twitter sx={{ color: "#1DA1F2" }} /></IconButton></Link>
                                                <Link target='_blank' to="https://www.instagram.com/shabdshodh/"><IconButton><Instagram sx={{ color: "#E1306C" }} /></IconButton></Link>
                                                <Link target='_blank' to="https://www.youtube.com/channel/UCd9s_OfrhT17F-1HgOoF46w"><IconButton><YouTube sx={{ color: "#ff0000" }} /></IconButton></Link>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </div>
                            </div>
                            <div className="main">
                                <Box sx={{ display: { xs: 'flex', md: "none" }, alignItems: "center", background: "#fff", borderRadius: "10px", position: "sticky", top: "63px", padding: "7px 0px 0px 0px", marginBottom: "5px", gap: "10px", maxWidth: "calc(100vw - 20px)", overflowY: "hidden", zIndex: "1" }}>
                                    <NavLink to="/">
                                        {({ isActive }) => (
                                            <Chip sx={{ fontWeight: "600", fontSize: "16px", fontFamily: "Mukta, sans-serif" }}
                                                avatar={<Avatar alt="Natacha" src={topnews} />}
                                                variant={isActive ? "filled" : "outlined"} label="मुख्य समाचार" color={isActive ? 'primary' : 'default'} />
                                        )}
                                    </NavLink>
                                    {props.category.map((item, index) => {
                                        return (
                                            <NavLink key={index} to={item.eng_sub_category}>
                                                {({ isActive }) => (
                                                    <Chip sx={{ fontWeight: "600", fontSize: "16px", fontFamily: "Mukta, sans-serif" }}
                                                        avatar={<Avatar alt="Natacha" src={apiUrl + "assets/cat-img/" + item.id + ".png"} />}
                                                        variant={isActive ? "filled" : "outlined"} label={item.sub_category} color={isActive ? 'primary' : 'default'} />
                                                )}
                                            </NavLink>
                                        )
                                    })}
                                </Box>
                                <Outlet context={{ notification: props.notification, setNotification: props.setNotification }} />
                            </div>
                            <div className="right-sidebar">
                                <div className="right-content-wrapper">
                                    <Paper sx={{ borderRadius: "15px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "0px" }}>
                                        {/* <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "10px" }}>
                                            <Avatar sx={{ maxWidth: "75px", height: "75px", borderRadius: "0px" }} src={growth}></Avatar>
                                            <Box sx={{ display: "flex", justifyContent: 'center', flexDirection: "column", padding: "10px", textAlign: "center" }}>
                                                <Typography variant='h1' sx={{ fontSize: "45px" }}><CountUp end={35000} />+</Typography>
                                                <Typography variant='h6'>Our Total Visiters</Typography>
                                            </Box>
                                        </Box> */}
                                        <Avatar src={ad1} sx={{ width: "100%", height: "auto", aspectRatio: 2 / 4, borderRadius: "0px" }}>600x400</Avatar>
                                        {/* <Avatar sx={{ width: "100%", height: "auto", aspectRatio: 3 / 5, borderRadius: "0px" }}>600x400</Avatar>
                                        <Avatar sx={{ width: "100%", height: "auto", aspectRatio: 1 / 1, borderRadius: "0px 0px 15px 15px" }}>300x300</Avatar> */}
                                        <Box sx={{ padding: "10px", textAlign: "center", fontSize: "14px" }}>
                                            <Typography variant='p'>Copyright © 2023-24 <br></br>Dainik Shabd Shodh - All Rights Reserved<br></br></Typography>
                                            <Typography variant='span'><Link to="faq" style={{ color: "#000" }}>FAQ</Link> |</Typography>
                                            <Typography variant='span'> <Link to="contact-us" style={{ color: "#000" }}>Contact</Link> |</Typography>
                                            <Typography variant='span'> <Link to="about-us" style={{ color: "#000" }}>About Us</Link></Typography><br></br>
                                            <Typography variant='span'> <Link to="privacy-policy" style={{ color: "#000" }}>Privacy Policy</Link></Typography>
                                        </Box>
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </Container >
                </Box >
            </Box >

        </>
    )
}

export default Main
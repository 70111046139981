import React, { useEffect, useState } from 'react'
import { Box, Paper, Button, IconButton, Badge, Avatar, Typography, Container, Menu, MenuItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import { Search, Notifications, HomeSharp, Newspaper, SlowMotionVideo, MoreVert, InfoOutlined, LiveHelpOutlined, PhoneOutlined, PolicyOutlined, Copyright } from '@mui/icons-material';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import sslogo from '../../assets/images/sslogo.png'
import axios from 'axios';
import { setNotification } from '../../store/globalSlice';
import { useMycontext } from './MyContext';
import CountUp from 'react-countup';
import { browserName, isMobile, isIOS } from 'react-device-detect';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material'

const Navbar = (props) => {

    const { apiUrl } = useMycontext();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const notification = useSelector((state) => state.global.notification);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getNotification = () => {
        axios.get(apiUrl + 'notification/get?datetime=' + notification.payload.calltime)
            .then(function (response) {
                if (response.data.status) {
                    props.setNotification(response.data.data);
                    dispatch(setNotification({ datetime: notification.payload.datetime, calltime: notification.payload.calltime, visible: true }));

                    setCount((response.data.data.filter((item) => {
                        return item.created_at > notification.payload.datetime;
                    })).length);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getNotification();
    }, []);

    return (
        <Box sx={{ background: "#fff", position: "sticky", top: "0px", zIndex: 99, paddingTop: { xs: "5px", md: "10px" } }}>
            <Paper sx={{ width: "100%", borderRadius: "15px", zIndex: "99" }}>
                <Container maxWidth="lg" sx={{
                    padding: { xs: "5px", md: "5px" }, display: "flex", justifyContent: "space-between", alignItems: "center"
                }}>
                    <Box sx={{ minWidth: "80px" }}>
                        <Link to="/">
                            <Box sx={{ alignItems: "center", display: "flex" }}>
                                <img src={sslogo} alt="Shabd Shodh" width="60px" style={{ padding: "5px 10px" }} />
                                <Typography variant='h2' color='#000'>दैनिक शब्द शोध</Typography>
                            </Box>
                        </Link>
                    </Box >
                    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
                            <NavLink to={"/"} >
                                {({ isActive }) => (
                                    <Button color={isActive ? "primary" : "secondary"}
                                        fullWidth
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "0px",
                                            border: "none",
                                            padding: "0px",
                                            gap: "10px",
                                            marginRight: "40px"
                                        }}
                                    >
                                        <HomeSharp sx={{ fontSize: "25px" }} />
                                        <Typography variant='h6' sx={{ fontSize: "20px" }}>होम</Typography>
                                    </Button>
                                )}
                            </NavLink>
                            {isIOS && isMobile && browserName.toLowerCase().includes("web") ? "" :
                                <NavLink to={"/video"} >
                                    {({ isActive }) => (
                                        <Button color={isActive ? "primary" : "secondary"}
                                            fullWidth
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "0px",
                                                border: "none",
                                                padding: "0px",
                                                gap: "10px",
                                                marginRight: "40px"
                                            }}
                                        >
                                            <SlowMotionVideo sx={{ fontSize: "25px" }} />
                                            <Typography variant='h6' sx={{ fontSize: "20px" }}>वीडियो</Typography>
                                        </Button>
                                    )}
                                </NavLink>
                            }

                            <NavLink to={"/search"} >
                                {({ isActive }) => (
                                    <Button color={isActive ? "primary" : "secondary"}
                                        fullWidth
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "0px",
                                            border: "none",
                                            padding: "0px",
                                            gap: "10px",
                                            marginRight: "40px"
                                        }}
                                    >
                                        <Search sx={{ fontSize: "25px" }} />
                                        <Typography variant='h6' sx={{ fontSize: "20px" }}>खोज</Typography>
                                    </Button>
                                )}
                            </NavLink>

                            <NavLink to={"/epaper"} >
                                {({ isActive }) => (
                                    <Button color={isActive ? "primary" : "secondary"}
                                        fullWidth
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "0px",
                                            border: "none",
                                            padding: "0px",
                                            gap: "10px",
                                            marginRight: "40px"
                                        }}
                                    >
                                        <Newspaper sx={{ fontSize: "25px" }} />
                                        <Typography variant='h6' sx={{ fontSize: "20px" }}>ई-पेपर</Typography>
                                    </Button>
                                )}
                            </NavLink>
                        </Box>
                        {/* <Box sx={{ maxWidth: "140px", display: { xs: "none", sm: "flex", md: "none" }, gap: "10px", alignItems: "center", marginRight: "10px" }}>
                            <Avatar sx={{ maxWidth: "35px", height: "35px", borderRadius: "0px" }} src={growth}></Avatar>
                            <Box sx={{ display: "flex", justifyContent: 'center', flexDirection: "column", padding: "0px", color: "#000" }}>
                                <Typography variant='h4' sx={{ fontSize: "20px", lineHeight: "20px" }}><CountUp end={35000} />+</Typography>
                                <Typography variant='h6' sx={{ fontSize: "9px" }}>Our Total Visiters</Typography>
                            </Box>
                        </Box> */}
                        <Link to="/notification">
                            <IconButton
                                sx={{ padding: "5px" }}
                                color="primary"
                            >
                                <Badge badgeContent={notification.payload.visible && count !== 0 ? <CountUp end={count} /> : 0} color="error">
                                    <Notifications />
                                </Badge>
                            </IconButton>
                        </Link>
                        <IconButton sx={{ padding: "5px" }} onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                        {/* <Link to="/login">
                            <IconButton sx={{ padding: "0px 10px" }}>
                                <Avatar src=""></Avatar>
                            </IconButton>
                        </Link> */}
                    </Box>
                </Container>
            </Paper >

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '20ch',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => { navigate('/faq'); handleClose(); }}>
                    <ListItemIcon>
                        <LiveHelpOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>FAQ</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { navigate('/about-us'); handleClose(); }}>
                    <ListItemIcon>
                        <InfoOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>About</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { navigate('/contact-us'); handleClose(); }}>
                    <ListItemIcon>
                        <PhoneOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Contact</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    navigate('/privacy-policy'); handleClose();
                }
                }>
                    <ListItemIcon>
                        <PolicyOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Privacy Policy</ListItemText>
                </MenuItem>
                <Divider />
                <Box sx={{ display: "flex", gap: "2px", justifyContent: "center" }}>
                    <Link target='_blank' to="https://www.facebook.com/dainik.shabdshodh/"><IconButton><Facebook sx={{ color: "#1877F2" }} /></IconButton></Link>
                    <Link target='_blank' to="https://twitter.com/shabdshodh"><IconButton><Twitter sx={{ color: "#1DA1F2" }} /></IconButton></Link>
                    <Link target='_blank' to="https://www.instagram.com/shabdshodh/"><IconButton><Instagram sx={{ color: "#E1306C" }} /></IconButton></Link>
                    <Link target='_blank' to="https://www.youtube.com/channel/UCd9s_OfrhT17F-1HgOoF46w"><IconButton><YouTube sx={{ color: "#ff0000" }} /></IconButton></Link>
                </Box>
                <MenuItem disabled>
                    <ListItemIcon>
                        <Copyright fontSize="small" />
                    </ListItemIcon>
                    <Typography sx={{ textAlign: 'center' }} variant='p'>2023-24 - Dainik<br></br> Shabd Shodh</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default Navbar
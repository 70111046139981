import { Paper, Box, Typography, Avatar, InputLabel, IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { useMycontext } from '../components/global/MyContext';
import { Link, useParams } from 'react-router-dom';
import CircleLoading from '../components/global/circleloading';
import { Helmet } from 'react-helmet';
import { Facebook, Share, Twitter, WhatsApp } from '@mui/icons-material';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';



const CatPost = () => {

    const { apiUrl } = useMycontext();
    const { url } = useParams();
    const [post, setPost] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const getRecent = (pg, flag) => {
        setLoading(true);
        axios.get(apiUrl + 'post/get?url=' + url + '&offset=' + pg)
            .then(function (response) {
                if (response.data.status) {
                    if (flag) {
                        setPost(response.data.data);
                    }
                    else {
                        setPost((prev) => [...prev, ...response.data.data]);
                    }
                    setLoading(false);
                }
                else {
                    if (flag) {
                        setPost([]);
                    }
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const handelInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight) {
                setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);

    useEffect(() => {
        getRecent(0, true);
        setPage(0);
    }, [url]);

    useEffect(() => {
        getRecent(page, false);
    }, [page]);

    return (
        <>
            <Helmet>
                <title>{post[0]?.sub_category}</title>
            </Helmet>
            {loading && <CircleLoading />}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                {post.map((item, index) => {
                    const url = `https://shabdshodh.in/share/post?url=${item.eng_sub_category}&id=${item.id}`;
                    return (
                        <Paper key={index} sx={{ borderRadius: "15px", padding: "10px 10px 0px 10px", position: "relative" }}>
                            <Link to={'/' + item.eng_sub_category + '/' + item.id} style={{ color: "#000" }}>
                                <Box sx={{ display: "flex", gap: "8px" }}>
                                    <Box sx={{ flex: { xs: 1.6, sm: 2.5, md: 2.8 } }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography variant="h6" sx={{ backgroundColor: item.color !== null ? item.color : "#ff6600", color: "#ffffff", padding: "0px 4px", borderRadius: "4px", textTransform: "capitalize" }}>{item.eng_category}</Typography>
                                            <InputLabel>
                                                {dayjs().diff(item.created_at, 'minutes') < 60
                                                    ? dayjs().diff(item.created_at, 'minutes') + " मिनट पहले"
                                                    : dayjs().diff(item.created_at, 'hour') < 24
                                                        ? dayjs().diff(item.created_at, 'hour') + " घंटे पहले"
                                                        : dayjs().diff(item.created_at, 'day') < 6
                                                            ? dayjs().diff(item.created_at, 'day') + " दिन पहले"
                                                            : dayjs(item.created_at).format('DD-MMM-YYYY')}
                                            </InputLabel>
                                        </Box>
                                        <Box sx={{ marginTop: "10px" }}>
                                            <Typography component='p' variant='p' sx={{ fontSize: { xs: "18px", md: "22px" }, fontWeight: "600", textAlign: "justify", textJustify: "inter-word" }}><span style={{ color: item.color }}>{item.color_title !== null ? item.color_title + " : " : ""}</span>{item.title}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ flex: 1, minHeight: "120px", maxHeight: "200px" }}>
                                        <Avatar src={apiUrl + "assets/post-img/" + item.id + ".png"} sx={{ borderRadius: "10px", width: "100%", height: "100%" }} />
                                    </Box>
                                </Box>
                            </Link>
                            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: "5px", paddingTop: "3px" }}>
                                <Box sx={{ flex: { xs: 1.6, sm: 2.5, md: 2.8 }, display: 'flex', alignItems: "center", gap: "5px" }}>
                                    <Avatar sx={{ height: "20px", maxWidth: "20px" }}></Avatar>
                                    <Typography color='secondary' variant='h6' sx={{ marginTop: "2px" }}> {item.posted_by}</Typography>
                                </Box>
                                <Box sx={{ flex: 1, display: "flex", gap: "5%", justifyContent: "center", alignItems: "center" }}>
                                    <IconButton sx={{ padding: "5px" }}><FacebookShareButton url={url}><Facebook /></FacebookShareButton></IconButton>
                                    <IconButton sx={{ padding: "5px" }}><TwitterShareButton url={url}><Twitter /></TwitterShareButton></IconButton>
                                    <IconButton sx={{ padding: "5px" }}><WhatsappShareButton url={url} title={post.title} separator=":: "><WhatsApp /></WhatsappShareButton></IconButton>
                                    <IconButton sx={{ padding: "5px", display: { xs: "none", sm: "block" } }} color='primary'
                                        onClick={() => {
                                            navigator.clipboard.writeText(url)
                                                .then(() => alert("Link Copied"))
                                        }}><Share />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Paper>
                    )
                })}
            </Box>
        </>
    )
}

export default CatPost
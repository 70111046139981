import React, { useEffect, useState } from 'react'
import { Box, Paper, IconButton, Typography, Container } from '@mui/material';
import { Share } from '@mui/icons-material';
import axios from 'axios';
import Pdf from '../components/pdf';
import { useMycontext } from '../components/global/MyContext';
import CircleLoading from '../components/global/circleloading';
import Navbar from '../components/global/navbar';
import Navdown from '../components/global/navdown';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Epaper = () => {

    const { apiUrl } = useMycontext();
    const navigate = useNavigate();
    const [paper, setPaper] = useState([]);
    const [loading, setLoading] = useState(true);

    const getPdfFiles = () => {
        axios.get(apiUrl + 'epaper/getall')
            .then(function (response) {
                if (response.data.status) {
                    setPaper(response.data.data);
                    setLoading(false);
                }
                else {
                    setPaper([]);
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getPdfFiles();
    }, []);

    return (
        <>
            <Helmet><title>ई-पेपर</title></Helmet>
            {loading && <CircleLoading />}
            <Box sx={{ padding: { xs: "0px 5px 0px 5px", md: "0px 10px 0px 10px" } }}>
                <Container sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: '10px', padding: { xs: "7px 5px 70px 5px", md: "10px 5px" }, width: "100%", overflowX: "hidden" }}>
                    {paper.map((item, index) => {
                        return (
                            <Paper key={index} sx={{ flexGrow: 1, maxWidth: "300px", textAlign: 'center', padding: "2px" }} onClick={() => { navigate('/epaper/' + item.date) }}>
                                <Pdf url={apiUrl + "assets/e-paper/" + item.file_name} ratio={1} control={false} />
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 10px" }}>
                                    <Typography variant='h5'>{item.date}</Typography>
                                    {/* <IconButton onClick={(event) => { event.stopPropagation(); }}><Share fontSize='small' color='primary' /></IconButton> */}
                                </Box>
                            </Paper>
                        )
                    })}
                </Container>
            </Box>
        </>
    )
}

export default Epaper
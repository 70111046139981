import React, { useRef, useState, useEffect } from 'react';
import { useInView } from "framer-motion";
import { Box, Container } from '@mui/material';
import axios from 'axios';
import Navbar from '../components/global/navbar';
import Navdown from '../components/global/navdown';
import { useMycontext } from '../components/global/MyContext';
import { useNavigate, useParams } from 'react-router-dom';

function FrameSection({ uid }) {

    const ref = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const isInView = useInView(ref, { once: false });

    return (
        <Box ref={ref} sx={{ minHeight: { xs: "calc(100vh - 140px)", md: "calc(100vh - 80px)" }, maxWidth: "500px", width: "100%", scrollSnapAlign: "start" }}>
            <iframe className="frameClass"
                src={isInView ? "https://www.youtube.com/embed/" + uid + "?&enablejsapi=1&vq=hd480p&autoplay=1&loop=1&iv_load_policy=1&rel=0&autohide=1&controls=1&modestbranding=1" : ""}
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>

        </Box>
    );
}

const VideoId = (props) => {

    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState([]);
    const { apiUrl } = useMycontext();
    const { id } = useParams();
    const navigate = useNavigate();
    const getVideo = () => {
        axios.get(apiUrl + 'video/get?offset=0&id=-UBfShetFzE')
            .then(function (response) {
                if (response.data.status) {
                    setVideos(response.data.data);
                    if (id == undefined) {
                        navigate(response.data.data[0]?.video_id);
                    }
                }
                else {
                    setVideos([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                setLoading(false);
            });
    }

    useEffect(() => {
        getVideo();
    }, []);

    return (
        <>
            <Box sx={{ padding: { xs: "0px 5px 0px 5px", md: "0px 10px 0px 10px" } }}>
                <Container maxWidth="fluid" sx={{ padding: { xs: "7px 5px 70px 5px", md: "10px 5px" }, height: { xs: "calc(100vh - 135px)", md: "calc(100vh - 75px)" }, overflow: "auto", gap: "2em", scrollSnapType: "y mandatory", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {videos.map((item, index) => {
                        return (
                            <FrameSection key={index} uid={item.video_id} />
                        )
                    })}
                </Container>
            </Box>
        </>
    )
}

export default VideoId